import Router from 'next/router';
import { useTranslation } from 'react-i18next';
import { APP_MAX_WIDTH } from '@constants/common';
import routes from '@constants/routes';
import { Image, Stack, Typography } from '..';
import Button from '../Button';

interface Props {
  title?: string;
  description: string;
  primaryLabel?: string;
  secondaryLabel?: string;
  onPressSecondary?: () => void;
  onPressPrimary?: () => void;
}

const NotFound: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const onReload = () => Router.reload();
  const onGoToHomePage = () => (window.location.href = routes.ROOT);
  const {
    title = t('error.page_not_found_title'),
    description = t('error.page_not_found_description'),
    primaryLabel = t('error.reload_label'),
    secondaryLabel = t('error.go_to_home_label'),
    onPressPrimary = onReload,
    onPressSecondary = onGoToHomePage,
  } = props;

  const notFoundImage = '/images/brand-not-found.png';

  return (
    <Stack
      position={'absolute'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={'xl'}
      height={'100%'}
      width={'100%'}
      maxWidth={APP_MAX_WIDTH}
    >
      <Image src={notFoundImage} width={228} />
      <Stack alignItems={'center'} justifyContent={'center'} spacing={'m'}>
        <Typography textAlign={'center'} size={'m'} variant={'bold'}>
          {title}
        </Typography>
        <Typography color={'uiDarkSecondary'} textAlign={'center'} size={'hs'}>
          {description}
        </Typography>
      </Stack>
      <Stack direction={'row'} spacing={'xs'}>
        <Button fullWidth onClick={onPressPrimary} variant={'outlined'}>
          <Typography>{primaryLabel}</Typography>
        </Button>
        <Button fullWidth onClick={onPressSecondary} variant={'contained'}>
          <Typography>{secondaryLabel}</Typography>
        </Button>
      </Stack>

      <Stack direction={'row'} spacing={'xxs'} alignItems={'center'} paddingTop={'3xxl'}>
        <Typography size={'xs'}>{t('general.powered_by_label')}</Typography>
        <Image src={'/images/runchise-logo-horizontal.png'} height={16} />
      </Stack>
    </Stack>
  );
};

export default NotFound;
