import { useCallback, useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { getOrderList } from '@api/order';
import { GetOrderListRequest, GetOrderListResponse } from '@api/order/types';
import { DEFAULT_ORDER_LIST_SEARCH_SPEC } from '@constants/order';
import { QUERY_KEY_ORDER_LIST } from '@constants/queryKey';
import { getUserCookies } from '@utils/auth';

interface OrderListQueryKey {
  key: string;
  payload: Partial<GetOrderListRequest>;
}

type UseQueryOrderListOptions = UseInfiniteQueryOptions<
  GetOrderListResponse,
  unknown,
  GetOrderListResponse,
  GetOrderListResponse,
  OrderListQueryKey[]
>;

const useQueryOrderList = (params = DEFAULT_ORDER_LIST_SEARCH_SPEC, config?: UseQueryOrderListOptions) => {
  const { enabled, ...otherConfig } = config ?? {};
  const { isLoggedIn } = getUserCookies();
  const queryKeys: OrderListQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_ORDER_LIST,
        payload: params,
      },
    ],
    [params]
  );

  const [lastId, setLastId] = useState<string>();

  const getNextPageParam = useCallback((lastResponse: GetOrderListResponse) => {
    const lastPage = lastResponse.lastPage;
    const currentPage = lastResponse.page;
    return currentPage + 1 > lastPage ? undefined : currentPage + 1;
  }, []);

  const getPreviousPageParam = useCallback((firstResponse: GetOrderListResponse) => {
    const currentPage = firstResponse.page;

    return currentPage - 1 < 0 ? undefined : currentPage - 1;
  }, []);

  const { data, isFetching, isError, isSuccess, fetchNextPage, hasNextPage } = useInfiniteQuery(
    queryKeys,
    ({ queryKey: [{ payload }], pageParam = 1 }) => getOrderList({ ...payload, lastId, page: pageParam }),
    {
      getNextPageParam,
      getPreviousPageParam,
      onSuccess: (res) => {
        const lastId = res?.pages?.slice?.(-1)?.[0]?.customerOrders?.slice?.(-1)?.[0]?.id;
        setLastId(lastId);
      },
      enabled: isLoggedIn && enabled,
      ...otherConfig,
    }
  );

  const customerOrders = useMemo(() => {
    const pages = data?.pages || [];
    return pages.reduce((acc: GetOrderListResponse['customerOrders'], currentRes) => {
      const currentCustomerOrders = currentRes.customerOrders || [];
      return [...acc, ...currentCustomerOrders];
    }, []);
  }, [data]);

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
    fetchNextPage,
    customerOrders,
    hasNextPage,
  };
};

export default useQueryOrderList;
