/**
 * This constant routes is used to list all the available routes for User, not for API.
 * API routes should be isolated in every API call wrapper.
 */
const routes = {
  ROOT: '/',
  PRODUCT_LIST: '/product',
  PRODUCT_SEARCH: '/product/search',
  PRODUCT_CART: '/product/cart',
  LOCATIONS: '/locations',
  SAVED_LOCATIONS: '/locations/saved',
  PROFILE_LOGIN: '/auth/login',
  PROFILE_OTP: '/auth/otp',
  PROFILE_EDIT: '/auth/profile',
  ORDER_PLACEMENT: '/order/placement',
  ORDER_LIST: '/order',
  ORDER_DETAIL: '/order/detail',
  ORDER_TRACKING: '/order/tracking',
  NOT_FOUND: '/404',
  BALANCE_HISTORY: '/balance_history',
  OPEN_BILL_DETAIL: '/dine_in/open_bill_detail',
  CLOSED_BILL_DETAIL: '/dine_in/closed_bill_detail',
  DINE_IN_PAYMENT_SUCCESS: '/dine_in/payment_success',
  DINE_AVAILABLE_PROMO: '/dine_in/available_promo',
  GUEST: '/auth/guest',
};

export const routeRegexDineInPaymentSuccess = [
  `^${routes.DINE_IN_PAYMENT_SUCCESS}/?$`,
  `^${routes.DINE_IN_PAYMENT_SUCCESS}$`,
];

export default routes;
