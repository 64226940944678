import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { getSavedCreditCards } from '@api/order';
import { GetSavedCreditCardsResponse } from '@api/order/types';
import { QUERY_KEY_CREDIT_CARDS } from '@constants/queryKey';
import { getUserCookies } from '@utils/auth';

interface GetCreditCardsQueryKey {
  key: string;
}

type useQueryCreditCardsOptions = UseQueryOptions<
  GetSavedCreditCardsResponse,
  unknown,
  GetSavedCreditCardsResponse,
  GetCreditCardsQueryKey[]
>;

const useQueryCreditCards = (config?: useQueryCreditCardsOptions) => {
  const { isLoggedIn } = getUserCookies();

  const queryKeys: GetCreditCardsQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_CREDIT_CARDS,
      },
    ],
    []
  );

  const { data, isFetching, isLoading, isError, isSuccess } = useQuery(queryKeys, getSavedCreditCards, {
    enabled: !!isLoggedIn,
    ...config,
  });

  return {
    queryKeys,
    data,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useQueryCreditCards;
